import {select, put, call, all, takeLatest} from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { initialize, change, getFormValues } from 'redux-form';
import i18n from '../../i18n';
import {round_decimal, dateTimeZones} from '../../util/formatFunctions'
import {downloadDocument} from '../../util/util'

// Constants
import actionTypes from '../../constants/actions/poolExtracciones/poolExtracciones'

// Actions
import {comboRaza, fetchComboTipoDiluyente} from '../../actions/combos/combos'
import {openSimpleModal, openMensajeAlerta, setLoadingTrue, setLoadingFalse, reloadComponent} from '../../actions/common'
import {
  fetchPoolExtraccionesSuccess, deseleccionarTodosPoolExtracciones, fetchCodigoPoolExtraccionesSuccess, recuperarDatosFormPoolExtraccionSuccess,
  initializeFormPoolExtraccionSuccess, setAsignacionAnalisisPool, fetchTokenMagavisionPoolSuccess,
  abrirModalAsignacionAnalisisPoolSuccess, cerrarModalAsignacionAnalisisPoolSuccess, guardarModalAsignacionAnalisisPoolSuccess,
  fetchAnalisisDisponiblesSuccess
} from '../../actions/poolExtracciones/poolExtracciones'
import { fetchExtraccionesPendientesDeAsignarSuccess } from '../../actions/asignacionPoolVerraco/asignacionAnalisisPool';

// Services
import getPoolExtraccionesListService from '../../services/poolExtracciones/getPoolExtraccionesList'
import getFiltrarPoolExtraccionesListService from '../../services/poolExtracciones/getFiltrarPoolExtraccionesList'
import deletePoolExtraccionService from '../../services/poolExtracciones/deletePoolExtraccion'
import getAnalisisDisponiblesPoolListService from '../../services/poolExtracciones/getAnalisisDisponiblesPoolList'
import getFiltrarAnalisisDisponiblesPoolListService from '../../services/poolExtracciones/getFiltrarAnalisisDisponiblesPoolList'
import deleteSeleccionPoolExtraccionService from '../../services/poolExtracciones/deleteSeleccionPoolExtraccion'
import getCrearCsvPoolExtraccionesService from '../../services/poolExtracciones/getCrearCsvPoolExtracciones'
import getImprimirPdfPoolExtraccionesService from '../../services/poolExtracciones/getImprimirPdfPoolExtracciones'
import getPoolExtraccionService from '../../services/poolExtracciones/getPoolExtraccion'
import getCodigoPoolExtraccionesService from '../../services/poolExtracciones/getCodigoPoolExtracciones'
import getTokenMagavisionPoolService from '../../services/poolExtracciones/getTokenMagavisionPool'
import submitNuevoPoolExtraccionService from '../../services/poolExtracciones/submitNuevoPoolExtraccion'
import submitEditarPoolExtraccionService from '../../services/poolExtracciones/submitEditarPoolExtraccion'
import generarPDFEtiqueta from '../../services/poolExtracciones/generarPDFEtiqueta'
import generarPDFPantalla from '../../services/poolExtracciones/getGenerarPDFPantalla'
import getImprimirEtiquetaPDFPool from '../../services/poolExtracciones/getImprimirEtiquetaPDFPool'
import getImprimirMiniEtiquetaPool from '../../services/poolExtracciones/getImprimirMiniEtiquetaPool'
import getExtraccionesPendienteAsignar from '../../services/AsignacionPoolVerraco/getExtraccionesPendienteAsignar';
import getObtenerNumeroPool from '../../services/AsignacionPoolVerraco/getObtenerNumeroPool';
import getCrearExcelBatchUSAService from '../../services/poolExtracciones/getCrearExcelBatchUSA'
import printronixService from '../../services/comun/printronix'


// Sagas
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'
import {
  comboOperarioPredeterminado as comboOperarioPredeterminadoSaga, comboTipoDosis as comboTipoDosisSaga,
  fetchComboTipoDiluyente as fetchComboTipoDiluyenteSaga, comboRaza as comboRazaSaga
} from '../combos/combos'
import {fetchPanelControl as fetchPanelControlSaga} from '../panelControl/panelControl'


export function * fetchPoolExtracciones ({filtros}) {  
  try {
    let auth = yield select(state => state.auth)
    let poolExtraccionesList = []
    if (filtros) {
      poolExtraccionesList = yield call(getFiltrarPoolExtraccionesListService, filtros, auth.token)
    } else {    
      poolExtraccionesList = yield call(getPoolExtraccionesListService)
    }
    yield put(fetchPoolExtraccionesSuccess(poolExtraccionesList.pools, poolExtraccionesList.totales, filtros))    
    let analisisExtraccionesPendientesAsignarList = yield call(getExtraccionesPendienteAsignar, filtros, auth.token);     
    yield put(fetchExtraccionesPendientesDeAsignarSuccess(analisisExtraccionesPendientesAsignarList));
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchPoolExtracciones () {
  yield takeLatest(actionTypes.FETCH_POOL_EXTRACCIONES, fetchPoolExtracciones)
}

export function * onDeletePoolExtraccion ({idPoolExtraccion}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let filtros = yield select(state => state.analisisExtracciones.filtros)
      const {eliminado} = yield call(deletePoolExtraccionService, idPoolExtraccion, null)
      yield call(fetchPoolExtracciones, {filtros})
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOnDeletePoolExtraccion () {
  yield takeLatest(actionTypes.ON_DELETE_POOL_EXTRACCION, onDeletePoolExtraccion)
}

export function * deleteSeleccionPoolExtracciones ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let filtros = yield select(state => state.analisisExtracciones.filtros)
      const {eliminado} = yield call(deleteSeleccionPoolExtraccionService, list, auth.token)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deseleccionarTodosPoolExtracciones())
      yield put(initialize('FiltrosPoolExtracciones'))
      const inicializaTabla = yield call(fetchPoolExtracciones, {filtros})
      yield put(initialize('simpleTable'))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteSeleccionPoolExtracciones () {
  yield takeLatest(actionTypes.DELETE_SELECCION_POOL_EXTRACCIONES, deleteSeleccionPoolExtracciones)
}

export function * crearCsvPoolExtracciones ({list}) {
  try {
    let auth = yield select(state => state.auth)
    // annadir filtros de seleccionados

    let filtrosSeleccionados = yield select(state => state.form.simpleTable.values);
    
    const idSeleccionados = Object.entries(filtrosSeleccionados).reduce((acc, filtro) => {      
      const [check,seleccionado] = filtro;      
      const id = check.slice('check'.length, check.length);      
      if (seleccionado) {        
        acc.push(id);
      } 
      return acc;     
    },[]);

    list = {...list, idSeleccionados}    
    const datosExcelPoolExtracciones = yield call(getCrearCsvPoolExtraccionesService, list, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(datosExcelPoolExtracciones.excel)
    let name = i18n.t('MENU.EXTRACCIONES.POOL') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCrearCsvPoolExtracciones () {
  yield takeLatest(actionTypes.CREATE_CSV_POOL_EXTRACCIONES, crearCsvPoolExtracciones)
}

export function * imprimirPdfPoolExtracciones ({list}) {
  try {
    let auth = yield select(state => state.auth)
        // annadir filtros de seleccionados

    let filtrosSeleccionados = yield select(state => state.form.simpleTable.values);
    
    const idSeleccionados = Object.entries(filtrosSeleccionados).reduce((acc, filtro) => {      
      const [check,seleccionado] = filtro;      
      const id = check.slice('check'.length, check.length);      
      if (seleccionado) {        
        acc.push(id);
      } 
      return acc;     
    },[]);

    list = {...list, idSeleccionados}  
    const datosPdfPoolExtracciones = yield call(getImprimirPdfPoolExtraccionesService, list, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfPoolExtracciones.pdf)
    let name = i18n.t('MENU.EXTRACCIONES.POOL') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchImprimirPdfPoolExtracciones () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_POOL_EXTRACCIONES, imprimirPdfPoolExtracciones)
}

export function * submitFormPoolExtraccion ({values, idPoolExtraccion, history, callback}) {
  try {
    let state = yield select(state => state)
    let preparedValues = {
      fechaExtraccion: values.fechaExtraccion,
      nombre: values.nombrePool,
      raza: values.raza,
      codigo: values.codigoPool,
      operario: values.operarioProcesado,
      volumen: values.volumen,
      tipoDosis: values.tipoDosis,
      motilidadTotal: values.motilidad,
      motilidadMasal: values.motilidadMasal,
      mt: values.mt,
      mp: values.mp,
      myn: values.myn,
      dcl: values.dcl,
      dsl: values.dsl,
      dap: values.dap,
      vcl: values.vcl,
      vsl: values.vsl,
      vap: values.vap,
      str: values.str,
      lin: values.lin,
      wob: values.wob,
      alh: values.alh,
      bcf: values.bcf,
      mad: values.mad,
      velRapidos: values.velRapidos,
      velMedios: values.velMedios,
      velLentos: values.velLentos,
      velEstaticos: values.velEstaticos,      
      totalAcrosomas: values.camposCalculados && values.camposCalculados.totalAcrosomas ? values.camposCalculados.totalAcrosomas : 0,
      porTotalAcrosomas: values.camposCalculados && values.camposCalculados.percentAcrosomas ? values.camposCalculados.percentAcrosomas : 0,
      faCabezas: values.camposCalculados && values.camposCalculados.faCabezas ? values.camposCalculados.faCabezas : 0,
      porFaCabezas: values.camposCalculados && values.camposCalculados.faCabezasPercent ? values.camposCalculados.faCabezasPercent : 0,
      faColas: values.camposCalculados && values.camposCalculados.faColas ? values.camposCalculados.faColas : 0,
      porFaColas: values.camposCalculados && values.camposCalculados.faColasPercent ? values.camposCalculados.faColasPercent : 0,
      faColasPlegadas: values.camposCalculados && values.camposCalculados.faColasPlegadas ? values.camposCalculados.faColasPlegadas : 0,
      faColasPlegadasPercent: values.camposCalculados && values.camposCalculados.faColasPlegadasPercent ? values.camposCalculados.faColasPlegadasPercent : 0,
      faColasOvillo: values.camposCalculados && values.camposCalculados.faColasOvillo ? values.camposCalculados.faColasOvillo : 0,
      faColasOvilloPercent: values.camposCalculados && values.camposCalculados.faColasOvilloPercent ? values.camposCalculados.faColasOvilloPercent : 0,
      faGotaProximal: values.camposCalculados && values.camposCalculados.faGotaProximal ? values.camposCalculados.faGotaProximal : 0,
      faTotal: values.camposCalculados && values.camposCalculados.faTotal ? values.camposCalculados.faTotal : 0,
      porFaGotaProximal: values.camposCalculados && values.camposCalculados.faGotaProximalPercent ? values.camposCalculados.faGotaProximalPercent : 0,
      faGotaDistal: values.camposCalculados && values.camposCalculados.faGotaDistal ? values.camposCalculados.faGotaDistal : 0,
      porFaGotaDistal: values.camposCalculados && values.camposCalculados.faGotaDistalPercent ? values.camposCalculados.faGotaDistalPercent : 0,
      porFaTotal: values.camposCalculados && values.camposCalculados.faTotalPercent ? values.camposCalculados.faTotalPercent : 0,
      concentracionTotal: values.concentracionTotal,
      concentracionUtil: values.concentracionUtil,
      concentracionXDosisAplicada: values.concentracionXDosisAplicada,
      concentracionXDosisUtil: values.concentracionXDosisUtil,
      dosisTeoricas: values.dosisTeoricas,
      dosisReales: values.dosisReales,
      poolEliminado: values.poolEliminado,
      observaciones: values.observaciones,
      observacionesPoolEliminado: values.observacionesPoolEliminado,
      analisis: values.analisis.map((analisis) => {
        return { idAnalisis: analisis.idAnalisis, idDilucion: analisis.idDilucion }
      }),
      t_dtv_main: state.poolExtracciones.pool.t_dtv_main
    }

    // Si se recibe el idPoolExtraccion se hara una actualización de dicho pool, sino se creara uno nuevo.
    let estadoPeticion
    if (idPoolExtraccion) {
      preparedValues.idPool = idPoolExtraccion
      preparedValues.volumenDiluyente = values.volumenDiluyente
      estadoPeticion = yield call(submitEditarPoolExtraccionService, preparedValues, state.auth.token)
    } else {
      estadoPeticion = yield call(submitNuevoPoolExtraccionService, preparedValues, state.auth.token)
      if(estadoPeticion.error && estadoPeticion.error=='check_nombre_pool'){
        yield put(openMensajeAlerta('no-insercion-pool'))
        return
      }
    }
    estadoPeticion = estadoPeticion && (estadoPeticion.insertado || estadoPeticion.actualizado)

    if (estadoPeticion === true) {
      yield put(openMensajeAlerta(idPoolExtraccion ? 'modificacion' : 'insercion'))
      if (history) {
        yield history.push({pathname: state.poolExtracciones.asignacion_view ? '/AsignacionPoolVerraco' : '/PoolExtracciones', state: { from: history.location.pathname }})
      } else {
        yield put(initialize('formPoolExtraccion', {fechaExtraccion: new Date(dateTimeZones(state.auth.zonaHorariaString))}))
        yield call(initializeFormPoolExtraccion)
        yield call(fetchCodigoPoolExtracciones)
      }
      if (callback) yield callback()
    } else {
      yield put(openMensajeAlerta(idPoolExtraccion ? 'no-modificacion' : 'no-insercion'))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchSubmitFormPoolExtraccion () {
  yield takeLatest(actionTypes.SUBMIT_FORM_POOL_EXTRACCION, submitFormPoolExtraccion)
}

export function * recuperarDatosFormPoolExtraccion ({idPoolExtraccion}) {
  try {
    yield put (setLoadingTrue());
    yield call(initializeFormPoolExtraccion)

    let state = yield select(state => state)
    let {analisis, pool} = yield call(getPoolExtraccionService, idPoolExtraccion)
    
    pool.nombrePool = pool.nombre
    pool.codigoPool = pool.codigo
    pool.motilidad = pool.motilidadTotal

    pool.camposCalculados = {
      totalAcrosomas: pool.totalAcrosomas,
      percentAcrosomas: pool.porTotalAcrosomas,
      faCabezas: pool.faCabezas,
      faCabezasPercent: pool.porFaCabezas,
      faColas: pool.faColas,
      faColasPercent: pool.porFaColas,
      faGotaProximal: pool.faGotaProximal,
      faGotaProximalPercent: pool.porFaGotaProximal,
      faGotaDistal: pool.faGotaDistal,
      faGotaDistalPercent: pool.porFaGotaDistal,
      faTotal: pool.faTotal,
      faTotalPercent: pool.porFaTotal,
      faColasPlegadas: pool.faColasPlegadas,
      faColasPlegadasPercent: pool.faColasPlegadasPercent,
      faColasOvillo: pool.faColasOvillo,
      faColasOvilloPercent: pool.faColasOvilloPercent
    }

    // Obtener informacion extra de los combos.
    state = yield select(state => state)
    if (pool.idRaza) {
      pool.raza = state.combos.comboRaza.find((element) => {
        return element.value === pool.idRaza
      })
    }
    if (pool.idOperario) {
      pool.operarioProcesado = state.combos.comboOperarioPredeterminado.find((element) => {
        return element.value === pool.idOperario
      })
    }
    if (pool.idTipoDosis) {
      pool.tipoDosis = state.combos.comboTipoDosis.find((element) => {
        return element.value === pool.idTipoDosis
      })
    }

    let totalDosisMililitros = analisis.reduce((acc, row) => {
      let tipoDosis = state.combos.comboTipoDosis.find((combo) => combo.value === row.idTipoDosis)
      return acc + (parseInt(row.dosisReales, 10) * parseFloat(tipoDosis.volumen))
    }, 0)

    yield put(initialize('formPoolExtraccion', pool))
    yield delay(300) // Requerido para evitar que se pierdan las dosis reales debido a que el tipo de dosis la machaca debido a los recalculos
    yield put(recuperarDatosFormPoolExtraccionSuccess(pool, analisis, totalDosisMililitros))
  } catch (error) {
    console.log(error)
  } finally {
    yield put (setLoadingFalse());
  }
}

export function * watchRecuperarDatosFormPoolExtraccion () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_FORM_POOL_EXTRACCION, recuperarDatosFormPoolExtraccion)
}

export function * initializeFormPoolExtraccion () {
  yield put(initializeFormPoolExtraccionSuccess())
  yield all([
    fetchPanelControlSaga(),
    comboRazaSaga(),
    comboOperarioPredeterminadoSaga(),
    comboTipoDosisSaga(),
    fetchComboTipoDiluyenteSaga()
  ])

  //numero pool
  let state = yield select(state => state)
  let formPoolExtraccionValues = yield getFormValues('formPoolExtraccion')(state)
  const numeroPool = yield call(getObtenerNumeroPool, null)
  let {codigo} = yield call(getCodigoPoolExtraccionesService)
  yield put(initialize('formPoolExtraccion', {...formPoolExtraccionValues, nombrePool: numeroPool.numeroPool, codigoPool: codigo}))
}

export function * watchInitializeFormPoolExtraccion () {
  yield takeLatest(actionTypes.INITIALIZE_FORM_POOL_EXTRACCION, initializeFormPoolExtraccion)
}

export function * fetchCodigoPoolExtracciones () {
  try {
    let {codigo} = yield call(getCodigoPoolExtraccionesService)
    yield put(change('formPoolExtraccion', 'codigoPool', codigo))
    yield put(fetchCodigoPoolExtraccionesSuccess(codigo))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchCodigoPoolExtracciones () {
  yield takeLatest(actionTypes.FETCH_CODIGO_POOL_EXTRACCIONES, fetchCodigoPoolExtracciones)
}

export function * fetchTokenMagavisionPool ({idPoolExtraccion}) {
  try {
    const state = yield select(state => state)
    let {token, t_anl} = yield call(getTokenMagavisionPoolService, idPoolExtraccion)
    let t_dtv = state.poolExtracciones.pool.data.nombre || state.poolExtracciones.t_dtv
    yield put(fetchTokenMagavisionPoolSuccess(token, t_anl, t_dtv))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchTokenMagavisionPool () {
  yield takeLatest(actionTypes.FETCH_TOKEN_MAGAVISION_POOL, fetchTokenMagavisionPool)
}

export function * handlePoolCalcFunctionsOrden ({calcFunctionList}) {
  for (let calcFunction of calcFunctionList) {
    switch(calcFunction.name) {
      case 'calcConcentracionXDosisPool':
        yield call(calcConcentracionXDosisPool)
        break
      case 'calcNumeroDosisPool':
        yield call(calcNumeroDosisPool, calcFunction.args)
        break
      case 'reCalcConcentracionXDosisPool':
        yield call(reCalcConcentracionXDosisPool)
        break
      case 'calcVolumenDiluyentePool':
        yield call(calcVolumenDiluyentePool)
        break
      default:
        yield console.log('unhandler function: ', calcFunction.name)
    }
  }
}

export function * watchHandlePoolCalcFunctionsOrden () {
  yield takeLatest(actionTypes.HANDLE_POOL_CALC_FUNCTIONS_ORDEN, handlePoolCalcFunctionsOrden)
}

// TODO Eliminar si no es necesaria.
// Calcular la concentración x dosis, util o aplicada, dependiendo de la configuración del panel de control.
export function * calcConcentracionXDosisPool () {
  try {
    let state = yield select(state => state)
    let {
      concentracionTotal, concentracionUtil, tipoDosis
    } = yield getFormValues('formPoolExtraccion')(state)
    let concentracionXDosisAplicada = 0
    let concentracionXDosisUtil = 0

    if (tipoDosis) {
      concentracionXDosisAplicada = parseFloat(tipoDosis.concentracion)
      concentracionXDosisUtil = concentracionXDosisAplicada
      if (concentracionTotal && concentracionUtil) {
        if (state.panelControl.ConcentracionAplicada) {
          concentracionXDosisUtil = (concentracionXDosisAplicada * concentracionUtil) / concentracionTotal
          concentracionXDosisUtil = round_decimal(concentracionXDosisUtil, 4, state.auth.separadorDec, state.auth.separadorMil)
        } else {
          concentracionXDosisAplicada = (concentracionXDosisUtil * concentracionTotal) / concentracionUtil
          concentracionXDosisAplicada = round_decimal(concentracionXDosisAplicada, 4, state.auth.separadorDec, state.auth.separadorMil)
        }
      }
    }
    yield put(change('formPoolExtraccion', 'concentracionXDosisAplicada', concentracionXDosisAplicada))
    yield put(change('formPoolExtraccion', 'concentracionXDosisUtil', concentracionXDosisUtil))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCalcConcentracionXDosisPool () {
  yield takeLatest(actionTypes.CALC_CONCENTRACION_X_DOSIS_POOL, calcConcentracionXDosisPool)
}

// Calcular el nº de dosis, dicho valor se asignara tanto a las teóricas como a las reales.
export function * calcNumeroDosisPool ({updateAllDosis = true}) {
  try {
    let state = yield select(state => state)
    let { tipoDosis, volumen, concentracionUtil } = yield getFormValues('formPoolExtraccion')(state)    
    let dosis = 0
    if (tipoDosis && state.poolExtracciones.pool.totalDosisMililitros) {
      // dosis = Math.round(parseFloat(state.poolExtracciones.pool.totalDosisMililitros) / parseFloat(tipoDosis.volumen))
      dosis = Math.round(volumen * parseFloat(concentracionUtil) / parseFloat(tipoDosis.concentracion) / 1000)
    }

    if (updateAllDosis) {
      yield put(change('formPoolExtraccion', 'dosisTeoricas', dosis))
    }
    yield put(change('formPoolExtraccion', 'dosisReales', dosis))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCalcNumeroDosisPool () {
  yield takeLatest(actionTypes.CALC_NUMERO_DOSIS_POOL, calcNumeroDosisPool)
}

export function * reCalcConcentracionXDosisPool () {
  try {
    let state = yield select(state => state)
    let {
      volumen, concentracionTotal, concentracionUtil, dosisReales
    } = yield getFormValues('formPoolExtraccion')(state)

    if (volumen && dosisReales && concentracionUtil) {
      volumen = parseFloat(volumen)
      concentracionTotal = parseFloat(concentracionTotal)
      concentracionUtil = parseFloat(concentracionUtil)
      dosisReales = parseInt(dosisReales, 10)
      let newConcentracionXDosisUtil = ((volumen * concentracionUtil) / dosisReales) / 1000
      newConcentracionXDosisUtil = round_decimal(newConcentracionXDosisUtil, 4, state.auth.separadorDec, state.auth.separadorMil)
      let newConcentracionXDosisAplicada = (newConcentracionXDosisUtil * concentracionTotal) / concentracionUtil
      newConcentracionXDosisAplicada = round_decimal(newConcentracionXDosisAplicada, 4, state.auth.separadorDec, state.auth.separadorMil)

      yield put(change('formPoolExtraccion', 'concentracionXDosisAplicada', newConcentracionXDosisAplicada))
      yield put(change('formPoolExtraccion', 'concentracionXDosisUtil', newConcentracionXDosisUtil))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchReCalcConcentracionXDosisPool () {
  yield takeLatest(actionTypes.RE_CALC_CONCENTRACION_X_DOSIS_POOL, reCalcConcentracionXDosisPool)
}

export function* calcVolumenDiluyentePool() {
  console.log('entra')
  try {    
    let state = yield select(state => state)
    let {
      volumen, tipoDosis, dosisReales
    } = yield getFormValues('formPoolExtraccion')(state)    

    let volumenDiluyente = 0
    if (dosisReales && tipoDosis && volumen) {
      dosisReales = parseInt(dosisReales, 10)
      volumen = parseFloat(volumen)
      volumenDiluyente = dosisReales * parseFloat(tipoDosis.volumen) - volumen
      volumenDiluyente = round_decimal(volumenDiluyente, 2, state.auth.separadorDec, state.auth.separadorMil)      
    }

    if((state.auth.customizations && state.auth.customizations.includes("Netpork"))){
      let {
        volumenPredilucion
      } = yield getFormValues('formPoolExtraccion')(state)

      if (volumenDiluyente) {
        volumenDiluyente = volumenDiluyente - volumenPredilucion
      }
    }

    yield put(change('formPoolExtraccion', 'volumenDiluyente', volumenDiluyente))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchCalcVolumenDiluyentePool() {
  yield takeLatest(actionTypes.CALC_VOLUMEN_DILUYENTE_POOL, calcVolumenDiluyentePool)
}

export function * onDeleteAsignacionAnalisisPool ({idAnalisis}) {
  try {
    let asignadosAnalisisList = yield select(state => state.poolExtracciones.pool.asignadosAnalisisList)
    asignadosAnalisisList = asignadosAnalisisList.filter((analisisAsignado) => {
      return analisisAsignado.idAnalisis !== idAnalisis
    })

    const {totalDosis, totalDosisMililitros} = yield call(calcSumatorioAnalisisAsignados, {asignadosAnalisisList})
    yield call(calcSumatoriosPool, {asignadosAnalisisList, totalDosis, totalDosisMililitros, mantenerModal: false})
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOnDeleteAsignacionAnalisisPool () {
  yield takeLatest(actionTypes.ON_DELETE_ASIGNACION_ANALISIS_POOL, onDeleteAsignacionAnalisisPool)
}

export function * deleteSeleccionAsignacionAnalisisPool ({list}) {
  try {
    let asignadosAnalisisList = yield select(state => state.poolExtracciones.pool.asignadosAnalisisList)
    list.forEach((analisisDesasignar) => {
      asignadosAnalisisList = asignadosAnalisisList.filter((analisisAsignado) => {
        return analisisAsignado.idAnalisis !== analisisDesasignar.idAnalisis
      })
    })
    yield put(initialize('poolExtraccionesTable'))
    const {totalDosis, totalDosisMililitros} = yield call(calcSumatorioAnalisisAsignados, {asignadosAnalisisList})
    yield call(calcSumatoriosPool, {asignadosAnalisisList, totalDosis, totalDosisMililitros, mantenerModal: false})
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchDeleteSeleccionAsignacionAnalisisPool () {
  yield takeLatest(actionTypes.DELETE_SELECCION_ASIGNACION_ANALISIS_POOL, deleteSeleccionAsignacionAnalisisPool)
}

export function * abrirModalAsignacionAnalisisPool () {
  try {
    let auth = yield select(state => state.auth)
    let state = yield select(state => state)
    let {raza} = yield getFormValues('formPoolExtraccion')(state)

    yield put(comboRaza())
    yield put(fetchComboTipoDiluyente())

    let filtros = {
      "seleccionar-todos-modal-asignar-analisis-pool": false,
      fechaExtraccion: new Date(),
      idRaza: !raza && Object.keys(state.combos.comboRaza).length > 0 ? state.combos.comboRaza[0] : raza
    }

    yield put(initialize('FiltrosDisponiblesAnalisisPool', filtros))
    const comboTipoDosis = state.combos.comboTipoDosis.find((element) => {
      return element.predeterminado === true
    })
    yield put(initialize('ModalAsignacionAnalisisPool', {
      tipoDosis: comboTipoDosis,
      totalDosis: 0,
      formasAnormales: 0
    }))

    if (filtros.idRaza) {
      yield put(abrirModalAsignacionAnalisisPoolSuccess())
      yield call(fetchAnalisisDisponibles, {filtros})
    } else {
      yield put(fetchAnalisisDisponiblesSuccess([], filtros))
      yield put(abrirModalAsignacionAnalisisPoolSuccess())
    }    
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchAbrirModalAsignacionAnalisisPool () {
  yield takeLatest(actionTypes.ABRIR_MODAL_ASIGNACION_ANALISIS_POOL, abrirModalAsignacionAnalisisPool)
}

export function * cerrarModalAsignacionAnalisisPool () {
  try {
    yield put(cerrarModalAsignacionAnalisisPoolSuccess())
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchCerrarModalAsignacionAnalisisPool () {
  yield takeLatest(actionTypes.CERRAR_MODAL_ASIGNACION_ANALISIS_POOL, cerrarModalAsignacionAnalisisPool)
}

export function * guardarModalAsignacionAnalisisPool ({seleccionadosAnalisisList, mantenerModal}) {
  try {
    let state = yield select(state => state)
    let finalSeleccionadosAnalisisList = state.poolExtracciones.pool.asignadosAnalisisList.concat(seleccionadosAnalisisList)
    let {totalDosisMililitros, totalDosis} = yield call(calcSumatorioAnalisisAsignadosModal, {asignadosAnalisisList: finalSeleccionadosAnalisisList})

    //Alertas Pool
    let confirmed = true
    let panelControl = yield select(state => state.panelControl)
    //alerta num eyaculados    
    if(panelControl && panelControl.AlertaPoolNumEyaculados && panelControl.AlertaPoolNumEyaculados > 0){
      let poolNumEyaculados = finalSeleccionadosAnalisisList.length
      if(poolNumEyaculados >= panelControl.AlertaPoolNumEyaculados){
        confirmed = yield call(yesNoModalSaga, {modalType: 'alertaPoolNumEyaculados'})
      }
    }

    //alerta num dosis
    if(confirmed && panelControl && panelControl.AlertaPoolNumDosis && panelControl.AlertaPoolNumDosis > 0){
      if(totalDosis >= panelControl.AlertaPoolNumDosis){
        confirmed = yield call(yesNoModalSaga, {modalType: 'alertaPoolNumDosis'})
      }
    }

    if(confirmed){
      yield call(calcSumatoriosPool, {asignadosAnalisisList: finalSeleccionadosAnalisisList, totalDosis, totalDosisMililitros, mantenerModal})
    
      if (mantenerModal) {
        let filtrosDisponiblesAnalisisPool = yield getFormValues('FiltrosDisponiblesAnalisisPool')(state)
        filtrosDisponiblesAnalisisPool["seleccionar-todos-modal-asignar-analisis-pool"] = false
        yield put(change('FiltrosDisponiblesAnalisisPool', "seleccionar-todos-modal-asignar-analisis-pool", false))
        yield call(fetchAnalisisDisponibles, {filtros: filtrosDisponiblesAnalisisPool})
      }
    }
    
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchGuardarModalAsignacionAnalisisPool () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_ASIGNACION_ANALISIS_POOL, guardarModalAsignacionAnalisisPool)
}

export function * fetchAnalisisDisponibles ({filtros, callback}) {
  try {
    let state = yield select(state => state)
    let analisisExtraccionesList
    if (filtros && Object.keys(filtros).length > 1) {
      analisisExtraccionesList = yield call(getFiltrarAnalisisDisponiblesPoolListService, filtros, state.auth.token)
    } else {
      analisisExtraccionesList = yield call(getAnalisisDisponiblesPoolListService)
    }

    // Filtrar del resultado de analisis, todos aquellos que ya están incluidos en el pool.
    analisisExtraccionesList = analisisExtraccionesList.filter((analisis) => {
      return !state.poolExtracciones.pool.asignadosAnalisisList.find((asignadosAnalisis) => {
        return analisis.idAnalisis === asignadosAnalisis.idAnalisis
      })
    })

    let totalAcrosomas, faCabezas, faColas, faGotaDistal, faGotaProximal, buenos
    let formasAnormales = 0
    let formasAnormalesPercent = 0
    let percentAcrosomas = 0
    let faCabezasPercent = 0
    let faColasPercent = 0
    let faGotaDistalPercent = 0
    let faGotaProximalPercent = 0
    analisisExtraccionesList = analisisExtraccionesList.map((currAnalisisDisponible) => {
      totalAcrosomas = currAnalisisDisponible.totalAcrosomas ? parseInt(currAnalisisDisponible.totalAcrosomas, 10) : 0
      faCabezas = currAnalisisDisponible.faCabezas ? parseInt(currAnalisisDisponible.faCabezas, 10) : 0
      faColas = currAnalisisDisponible.faColas ? parseInt(currAnalisisDisponible.faColas, 10) : 0
      faGotaDistal = currAnalisisDisponible.faGotaDistal ? parseInt(currAnalisisDisponible.faGotaDistal, 10) : 0
      faGotaProximal = currAnalisisDisponible.faGotaProximal ? parseInt(currAnalisisDisponible.faGotaProximal, 10) : 0
      buenos = currAnalisisDisponible.buenos ? parseInt(currAnalisisDisponible.buenos, 10) : 0
      formasAnormales = currAnalisisDisponible.faTotal ? parseInt(currAnalisisDisponible.faTotal, 10) : 0
      formasAnormalesPercent = currAnalisisDisponible.faTotalPercent ? parseFloat(currAnalisisDisponible.faTotalPercent) : 0

      percentAcrosomas = totalAcrosomas !== 0 ? round_decimal(100 / parseInt(state.panelControl.espermatozoidesContar, 10) * totalAcrosomas, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
      faCabezasPercent = faCabezas !== 0 ? round_decimal((faCabezas * 100 / formasAnormales) * formasAnormalesPercent / 100, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
      faColasPercent = faColas !== 0 ? round_decimal((faColas * 100 / formasAnormales) * formasAnormalesPercent / 100, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
      faGotaDistalPercent = faGotaDistal !== 0 ? round_decimal((faGotaDistal * 100 / formasAnormales) * formasAnormalesPercent / 100, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
      faGotaProximalPercent = faGotaProximal !== 0 ? round_decimal((faGotaProximal * 100 / formasAnormales) * formasAnormalesPercent / 100, 2, state.auth.separadorDec, state.auth.separadorMil) : 0

      return {
        ...currAnalisisDisponible, totalAcrosomas, faCabezas, faColas, faGotaDistal, faGotaProximal, buenos, faTotal: formasAnormales,
        formasAnormales: formasAnormalesPercent, percentAcrosomas, faCabezasPercent, faColasPercent, faGotaProximalPercent, faGotaDistalPercent
      }
    })

    yield put(fetchAnalisisDisponiblesSuccess(analisisExtraccionesList, filtros))
    if (callback) yield callback()
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchFetchAnalisisDisponibles () {
  yield takeLatest(actionTypes.FETCH_ANALISIS_DISPONIBLES, fetchAnalisisDisponibles)
}

function * calcSumatorioAnalisisAsignados ({asignadosAnalisisList}) {
  try {
    let state = yield select(state => state)
    let formPoolExtraccionValues = yield getFormValues('formPoolExtraccion')(state)
    let totalDosisMililitros = 0
    let totalDosis = 0

    if (formPoolExtraccionValues.tipoDosis) {
      // Calcular la cantidad de dosis en mililitros sobre el conjunto de análisis seleccionados.
      // numDosis * volumenDosis
      totalDosisMililitros = asignadosAnalisisList.reduce((acc, row) => {
        let tipoDosis = state.combos.comboTipoDosis.find((combo) => combo.value === row.idTipoDosis)
        return acc + (parseInt(row.dosisReales, 10) * parseFloat(tipoDosis.volumen))
      }, 0)
      totalDosis = Math.round(totalDosisMililitros / parseFloat(formPoolExtraccionValues.tipoDosis.volumen))
    }
    return {
      totalDosisMililitros,
      totalDosis
    }
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

function * calcSumatorioAnalisisAsignadosModal ({asignadosAnalisisList}) {
  try {
    let state = yield select(state => state)
    let formPoolExtraccionValues = yield getFormValues('ModalAsignacionAnalisisPool')(state)
    console.log('calcSumatorioAnalisisAsignadosModal',formPoolExtraccionValues)
    let totalDosisMililitros = 0
    let totalDosis = 0

    if (formPoolExtraccionValues.tipoDosis) {
      // Calcular la cantidad de dosis en mililitros sobre el conjunto de análisis seleccionados.
      // numDosis * volumenDosis
      totalDosisMililitros = asignadosAnalisisList.reduce((acc, row) => {
        let tipoDosis = state.combos.comboTipoDosis.find((combo) => combo.value === row.idTipoDosis)
        return acc + (parseInt(row.dosisReales, 10) * parseFloat(tipoDosis.volumen))
      }, 0)
      totalDosis = Math.round(totalDosisMililitros / parseFloat(formPoolExtraccionValues.tipoDosis.volumen))
    }
    return {
      totalDosisMililitros,
      totalDosis
    }
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

function * calcSumatoriosPool ({asignadosAnalisisList, totalDosis, totalDosisMililitros, mantenerModal}) {
  try {
    let state = yield select(state => state)
    let formPoolExtraccionValues = yield getFormValues('formPoolExtraccion')(state)    

    let data = {
      volumen: 0,
      motilidad: 0,
      motilidadMasal: 0,
      mt: 0,
      mp: 0,
      myn: 0,
      concentracionTotal: 0,
      concentracionUtil: 0,
      concentracionXDosisAplicada: 0,
      concentracionXDosisUtil: 0,
      dosisTeoricas: totalDosis,
      dosisReales: totalDosis,
      camposCalculados: {
        totalAcrosomas: 0,
        percentAcrosomas: 0,
        faCabezas: 0,
        faCabezasPercent: 0,
        faColas: 0,
        faColasPercent: 0,
        faColasPlegadas: 0,
        faColasPlegadasPercent: 0,
        faColasOvillo: 0,
        faColasOvilloPercent: 0,
        faGotaProximal: 0,
        faGotaProximalPercent: 0,
        faGotaDistal: 0,
        faGotaDistalPercent: 0,
        faTotal: 0,
        faTotalPercent: 0
      },      
      dcl: 0,
      dsl: 0,
      dap: 0,
      vcl: 0,
      vsl: 0,
      vap: 0,
      str: 0,
      lin: 0,
      wob: 0,
      alh: 0,
      bcf: 0,
      mad: 0,
      velRapidos: 0,
      velMedios: 0,
      velLentos: 0,
      velEstaticos: 0
    }
    let motilidad, motilidadMasal, concentracionTotal, volumen, mt, mp, myn, dcl, dsl, dap, vcl, vsl, vap, str, lin, wob, alh, bcf, mad, velRapidos, velMedios, velLentos, velEstaticos
    let auxConcentracionTotalXVolumen = 0

    // Realizar sumatorio/ponderación de los campos calculados en base a los análisis seleccionados.
    asignadosAnalisisList.forEach((seleccionadoAnalisis) => {

      // Sumatorios
      data.volumen += seleccionadoAnalisis.volumen ? parseFloat(seleccionadoAnalisis.volumen) : 0
      data.camposCalculados.totalAcrosomas += seleccionadoAnalisis.totalAcrosomas ? parseInt(seleccionadoAnalisis.totalAcrosomas, 10) : 0
      data.camposCalculados.faCabezas += seleccionadoAnalisis.faCabezas ? parseInt(seleccionadoAnalisis.faCabezas, 10) : 0
      data.camposCalculados.faColas += seleccionadoAnalisis.faColas ? parseInt(seleccionadoAnalisis.faColas, 10) : 0
      data.camposCalculados.faColasPlegadas += seleccionadoAnalisis.faColasPlegadas ? parseInt(seleccionadoAnalisis.faColasPlegadas, 10) : 0
      data.camposCalculados.faColasOvillo += seleccionadoAnalisis.faColasOvillo ? parseInt(seleccionadoAnalisis.faColasOvillo, 10) : 0
      data.camposCalculados.faGotaProximal += seleccionadoAnalisis.faGotaProximal ? parseInt(seleccionadoAnalisis.faGotaProximal, 10) : 0
      data.camposCalculados.faGotaDistal += seleccionadoAnalisis.faGotaDistal ? parseInt(seleccionadoAnalisis.faGotaDistal, 10) : 0
      data.camposCalculados.faTotal += seleccionadoAnalisis.faTotal ? parseInt(seleccionadoAnalisis.faTotal, 10) : 0

      // Sumatorio ponderaciones
      motilidad = seleccionadoAnalisis.motilidadTotal ? parseFloat(seleccionadoAnalisis.motilidadTotal) : 0
      motilidadMasal = seleccionadoAnalisis.motilidadMasal ? parseFloat(seleccionadoAnalisis.motilidadMasal) : 0
      concentracionTotal = seleccionadoAnalisis.concentracionTotal ? parseFloat(seleccionadoAnalisis.concentracionTotal) : 0
      volumen = seleccionadoAnalisis.volumen ? parseFloat(seleccionadoAnalisis.volumen) : 0
      mt = seleccionadoAnalisis.mt ? parseFloat(seleccionadoAnalisis.mt) : 0
      mp = seleccionadoAnalisis.mp ? parseFloat(seleccionadoAnalisis.mp) : 0
      myn = seleccionadoAnalisis.myn ? parseFloat(seleccionadoAnalisis.myn) : 0
      dcl = seleccionadoAnalisis.dcl ? parseFloat(seleccionadoAnalisis.dcl) : 0
      dsl = seleccionadoAnalisis.dsl ? parseFloat(seleccionadoAnalisis.dsl) : 0
      dap = seleccionadoAnalisis.dap ? parseFloat(seleccionadoAnalisis.dap) : 0
      vcl = seleccionadoAnalisis.vcl ? parseFloat(seleccionadoAnalisis.vcl) : 0
      vsl = seleccionadoAnalisis.vsl ? parseFloat(seleccionadoAnalisis.vsl) : 0
      vap = seleccionadoAnalisis.vap ? parseFloat(seleccionadoAnalisis.vap) : 0
      str = seleccionadoAnalisis.str ? parseFloat(seleccionadoAnalisis.str) : 0
      lin = seleccionadoAnalisis.lin ? parseFloat(seleccionadoAnalisis.lin) : 0
      wob = seleccionadoAnalisis.wob ? parseFloat(seleccionadoAnalisis.wob) : 0
      alh = seleccionadoAnalisis.alh ? parseFloat(seleccionadoAnalisis.alh) : 0
      bcf = seleccionadoAnalisis.bcf ? parseFloat(seleccionadoAnalisis.bcf) : 0
      mad = seleccionadoAnalisis.mad ? parseFloat(seleccionadoAnalisis.mad) : 0
      velRapidos = seleccionadoAnalisis.velRapidos ? parseFloat(seleccionadoAnalisis.velRapidos) : 0
      velMedios = seleccionadoAnalisis.velMedios ? parseFloat(seleccionadoAnalisis.velMedios) : 0
      velLentos = seleccionadoAnalisis.velLentos ? parseFloat(seleccionadoAnalisis.velLentos) : 0
      velEstaticos = seleccionadoAnalisis.velEstaticos ? parseFloat(seleccionadoAnalisis.velEstaticos) : 0      

      data.motilidad += motilidad * concentracionTotal * volumen
      data.motilidadMasal += motilidadMasal *concentracionTotal * volumen
      data.mt += mt * concentracionTotal * volumen
      data.mp += mp * concentracionTotal * volumen
      data.myn += myn * concentracionTotal * volumen
      data.dcl += dcl * concentracionTotal * volumen
      data.dsl += dsl * concentracionTotal * volumen
      data.dap += dap * concentracionTotal * volumen
      data.vcl += vcl * concentracionTotal * volumen
      data.vsl += vsl * concentracionTotal * volumen
      data.vap += vap * concentracionTotal * volumen
      data.str += str * concentracionTotal * volumen
      data.lin += lin * concentracionTotal * volumen
      data.wob += wob * concentracionTotal * volumen
      data.alh += alh * concentracionTotal * volumen
      data.bcf += bcf * concentracionTotal * volumen
      data.mad += mad * concentracionTotal * volumen
      data.velRapidos += velRapidos * concentracionTotal * volumen
      data.velMedios += velMedios * concentracionTotal * volumen
      data.velLentos += velLentos * concentracionTotal * volumen
      data.velEstaticos += velEstaticos * concentracionTotal * volumen      
      data.camposCalculados.percentAcrosomas += seleccionadoAnalisis.percentAcrosomas ? seleccionadoAnalisis.percentAcrosomas * concentracionTotal * volumen : 0
      data.camposCalculados.faCabezasPercent += seleccionadoAnalisis.faCabezasPercent ? seleccionadoAnalisis.faCabezasPercent * concentracionTotal * volumen : 0
      data.camposCalculados.faColasPercent += seleccionadoAnalisis.faColasPercent ? seleccionadoAnalisis.faColasPercent * concentracionTotal * volumen : 0
      data.camposCalculados.faColasPlegadasPercent += seleccionadoAnalisis.faColasPlegadasPercent ? seleccionadoAnalisis.faColasPlegadasPercent * concentracionTotal * volumen : 0
      data.camposCalculados.faColasOvilloPercent += seleccionadoAnalisis.faColasOvilloPercent ? seleccionadoAnalisis.faColasOvilloPercent * concentracionTotal * volumen : 0
      data.camposCalculados.faGotaProximalPercent += seleccionadoAnalisis.faGotaProximalPercent ? seleccionadoAnalisis.faGotaProximalPercent * concentracionTotal * volumen : 0
      data.camposCalculados.faGotaDistalPercent += seleccionadoAnalisis.faGotaDistalPercent ? seleccionadoAnalisis.faGotaDistalPercent * concentracionTotal * volumen : 0
      data.camposCalculados.faTotalPercent += seleccionadoAnalisis.formasAnormales ? seleccionadoAnalisis.formasAnormales * concentracionTotal * volumen : 0
      data.concentracionTotal += concentracionTotal * volumen
      auxConcentracionTotalXVolumen += concentracionTotal * volumen
    })

    // Aplicar la ponderación.
    data.motilidad = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.motilidad / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.motilidadMasal = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.motilidadMasal / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.mt = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.mt / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.mp = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.mp / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.myn = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.myn / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.dcl = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.dcl / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.dsl = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.dsl / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.dap = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.dap / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.vcl = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.vcl / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.vsl = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.vsl / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.vap = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.vap / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.str = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.str / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.lin = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.lin / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.wob = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.wob / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.alh = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.alh / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.bcf = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.bcf / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.mad = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.mad / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.velRapidos = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.velRapidos / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.velMedios = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.velMedios / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.velLentos = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.velLentos / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.velEstaticos = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.velEstaticos / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0    
    data.camposCalculados.percentAcrosomas = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.camposCalculados.percentAcrosomas / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.camposCalculados.faCabezasPercent = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.camposCalculados.faCabezasPercent / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.camposCalculados.faColasPercent = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.camposCalculados.faColasPercent / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.camposCalculados.faColasPlegadasPercent = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.camposCalculados.faColasPlegadasPercent / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.camposCalculados.faColasOvilloPercent = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.camposCalculados.faColasOvilloPercent / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.camposCalculados.faGotaProximalPercent = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.camposCalculados.faGotaProximalPercent / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.camposCalculados.faGotaDistalPercent = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.camposCalculados.faGotaDistalPercent / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.camposCalculados.faTotalPercent = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.camposCalculados.faTotalPercent / auxConcentracionTotalXVolumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.concentracionTotal = auxConcentracionTotalXVolumen !== 0 ? round_decimal(data.concentracionTotal / data.volumen, 2, state.auth.separadorDec, state.auth.separadorMil) : 0

    // Calcular la concentración util.
    let descontar = 0
    if (state.panelControl.ConcentracionDosisFormasAnormales && data.camposCalculados.faTotalPercent) {
      descontar += data.camposCalculados.faTotalPercent
    }
    if (state.panelControl.ConcentracionDosisMotilidad && data.motilidad) {
      descontar += 100 - data.motilidadTotal // Motilidad (%)
    }
    if (state.panelControl.ConcentracionDosisMotilesProgresivos && data.mp) {
      descontar += 100 - data.mp
    }
    if (state.panelControl.ConcentracionDosisMotilesTotales && data.mt) {
      descontar += 100 - data.mt
    }
    //motil y normal con Magavision ML    
    if (state.panelControl.ConcentracionDosisFormasAnormales && state.panelControl.ConcentracionDosisMotilesTotales && state.panelControl.tipoAnalisisPredeterminado == 6) {      
      descontar = 100 - myn
    }
    if (data.concentracionTotal) {
      data.concentracionUtil = data.concentracionTotal * (100 - descontar) / 100
      data.concentracionUtil = round_decimal(data.concentracionUtil, 2, state.auth.separadorDec, state.auth.separadorMil)
    }

    // Calcular la concentración X Dosis util y aplicada.
    data.concentracionXDosisUtil = totalDosis !== 0 ? round_decimal(data.volumen * data.concentracionUtil / totalDosis / 1000, 4, state.auth.separadorDec, state.auth.separadorMil) : 0
    data.concentracionXDosisAplicada = data.concentracionUtil !== 0 ? round_decimal(data.concentracionXDosisUtil * data.concentracionTotal / data.concentracionUtil, 4, state.auth.separadorDec, state.auth.separadorMil) : 0
        
    data.raza = state.poolExtracciones.pool.data.raza ? state.poolExtracciones.pool.data.raza : getFormValues('FiltrosDisponiblesAnalisisPool')(state).idRaza
    data.tipoDosis = state.poolExtracciones.pool.data.tipoDosis ? state.poolExtracciones.pool.data.tipoDosis : getFormValues('ModalAsignacionAnalisisPool')(state).tipoDosis

    //Calcular volumen Diluyente  
    let dosisRealesAux = 0
    let volumenAux = 0
    let volumenDiluyente = 0
    dosisRealesAux = parseInt(data.dosisReales, 10)
    volumenAux = parseFloat(data.volumen)
    volumenDiluyente = dosisRealesAux * parseFloat(formPoolExtraccionValues.volumenTipoDosis) - volumenAux
    volumenDiluyente = round_decimal(volumenDiluyente, 2, state.auth.separadorDec, state.auth.separadorMil)
    data.volumenDiluyente = volumenDiluyente

    yield put(initialize('formPoolExtraccion', {...formPoolExtraccionValues, ...data}, {keepDirty: true }))
    yield put(change('formPoolExtraccion', 'dosisReales', data.dosisReales))

    yield put(guardarModalAsignacionAnalisisPoolSuccess(asignadosAnalisisList, totalDosisMililitros, mantenerModal))
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}

export function * imprimirPantalla ({idPool}) {
  try {    
    // const state = yield select(state => state)
    // let formPoolExtraccionData = yield getFormValues('formPoolExtraccion')(state)
    // let dosisReales, volumenTipoDosis, nombre, nombreRaza, fechaExtraccion, volumenDiluyente

    // dosisReales = formPoolExtraccionData.dosisReales || 0
    // volumenTipoDosis = (formPoolExtraccionData.tipoDosis && formPoolExtraccionData.tipoDosis.volumen) || 0
    // nombre = (formPoolExtraccionData.nombrePool) || ''
    // nombreRaza = (formPoolExtraccionData.raza && formPoolExtraccionData.raza.label) || ''
    // fechaExtraccion = formPoolExtraccionData.fechaExtraccion || ''
    // volumenDiluyente = (state.poolExtracciones.pool.asignadosAnalisisList && state.poolExtracciones.pool.asignadosAnalisisList.reduce((acc, currentAnalisis) => { return acc + parseFloat(currentAnalisis.volumenDiluyente || 0) }, 0)) || 0

    //const datosPdf = yield call(generarPDFEtiqueta, {dosisReales, volumenTipoDosis, nombre, nombreRaza, fechaExtraccion, volumenDiluyente, idPool})
    const datosPdf = yield call(generarPDFPantalla, idPool);    
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdf.pdf)
    let name = i18n.t('ANALISIS_EXTRACCIONES.FORM.BUTTONS.PDF_ETIQUETA') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchImprimirPantalla () {
  yield takeLatest(actionTypes.IMPRIMIR_PANTALLA_POOL, imprimirPantalla)
}
export function * descargarEtiquetaPdf ({idPool}) {
  try {
    const state = yield select(state => state)
    let formPoolExtraccionData = yield getFormValues('formPoolExtraccion')(state)
    let dosisReales, volumenTipoDosis, nombre, nombreRaza, fechaExtraccion, volumenDiluyente

    dosisReales = formPoolExtraccionData.dosisReales || 0
    volumenTipoDosis = (formPoolExtraccionData.tipoDosis && formPoolExtraccionData.tipoDosis.volumen) || 0
    nombre = (formPoolExtraccionData.nombrePool) || ''
    nombreRaza = (formPoolExtraccionData.raza && formPoolExtraccionData.raza.label) || ''
    fechaExtraccion = formPoolExtraccionData.fechaExtraccion || ''
    volumenDiluyente = (state.poolExtracciones.pool.asignadosAnalisisList && state.poolExtracciones.pool.asignadosAnalisisList.reduce((acc, currentAnalisis) => { return acc + parseFloat(currentAnalisis.volumenDiluyente || 0) }, 0)) || 0

    const datosPdf = yield call(generarPDFEtiqueta, {dosisReales, volumenTipoDosis, nombre, nombreRaza, fechaExtraccion, volumenDiluyente, idPool})
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdf.pdf)
    let name = i18n.t('ANALISIS_EXTRACCIONES.FORM.BUTTONS.PDF_ETIQUETA') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDescargarEtiquetaPdf () {
  yield takeLatest(actionTypes.DESCARGAR_ETIQUETA_PDF, descargarEtiquetaPdf)
}

export function * imprimirEtiquetaPDFPool ({idPool}) {
  try {
    const state = yield select(state => state)
    let formPoolExtraccionData = yield getFormValues('formPoolExtraccion')(state)
    let dosisReales, volumenTipoDosis, nombre, nombreRaza, fechaExtraccion, volumenDiluyente

    dosisReales = formPoolExtraccionData.dosisReales || 0
    volumenTipoDosis = (formPoolExtraccionData.tipoDosis && formPoolExtraccionData.tipoDosis.volumen) || 0
    nombre = (formPoolExtraccionData.nombrePool) || ''
    nombreRaza = (formPoolExtraccionData.raza && formPoolExtraccionData.raza.label) || ''
    fechaExtraccion = formPoolExtraccionData.fechaExtraccion || ''
    volumenDiluyente = formPoolExtraccionData.volumenDiluyente || ''
    //volumenDiluyente = (state.poolExtracciones.pool.asignadosAnalisisList && state.poolExtracciones.pool.asignadosAnalisisList.reduce((acc, currentAnalisis) => { return acc + parseFloat(currentAnalisis.volumenDiluyente || 0) }, 0)) || 0

    const etiquetaPdf = yield call(getImprimirEtiquetaPDFPool, {dosisReales, volumenTipoDosis, nombre, nombreRaza, fechaExtraccion, volumenDiluyente, idPool})
    if(etiquetaPdf.check_printronix){
      let data = etiquetaPdf.pdf
      let status = yield call(printronixService, {data : data})
    }else{
      let selected_device;
      let devices = [];
      window.BrowserPrint.getDefaultDevice("printer", function(device){
        selected_device = device;
        devices.push(device);
        window.BrowserPrint.getLocalDevices(function(device_list){
          for(let i = 0; i < device_list.length; i++){
            let device = device_list[i];
            if(!selected_device || device.uid != selected_device.uid){
              devices.push(device);
            }
          }
          selected_device.send(etiquetaPdf.pdf, undefined, undefined)
        },
        function(){console.log("Error getting local devices")},"printer");
      }, function(error){
        console.log(error);
      })
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchImprimirEtiquetaPDFPool () {
  yield takeLatest(actionTypes.IMPRIMIR_ETIQUETA_PDF_POOL, imprimirEtiquetaPDFPool)
}

export function * imprimirMiniEtiquetaPool ({idPool}) {  

  try {
    const state = yield select(state => state)
    
    const formatoFechaHora = state.auth.formaFechaHora;    
    const etiquetaPdf = yield call(getImprimirMiniEtiquetaPool, {idPool,formatoFechaHora })    
    
    let selected_device;
    let devices = [];
    window.BrowserPrint.getDefaultDevice("printer", function(device){
      selected_device = device;
      devices.push(device);
      window.BrowserPrint.getLocalDevices(function(device_list){
        for(let i = 0; i < device_list.length; i++){
          let device = device_list[i];
          if(!selected_device || device.uid != selected_device.uid){
            devices.push(device);
          }
        }
        selected_device.send(etiquetaPdf.pdf, undefined, undefined)
      },
      function(){console.log("Error getting local devices")},"printer");
    }, function(error){
      console.log(error);
    })
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchImprimirMiniEtiquetaPool () {
  yield takeLatest(actionTypes.IMPRIMIR_MINI_ETIQUETA_POOL, imprimirMiniEtiquetaPool)
}




export function * crearExcelBatchUSA ({list}) {
  try {
    let auth = yield select(state => state.auth)
    /*
    desde: "2022-03-01"
    hasta: "2022-03-03"
    */
    const datosExcelPoolExtracciones = yield call(getCrearExcelBatchUSAService, list, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(datosExcelPoolExtracciones.excel)
    //BATCH EBV 12-2021
    let name = "BATCH INDEX" + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCrearExcelBatchUSA() {
  yield takeLatest(actionTypes.CREAR_EXCEL_BATCH_USA, crearExcelBatchUSA)
}


