import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

class ModalOperario extends Component {



  handleImprimirEtiquetaOperario (values) {
    this.props.actions.imprimirEtiquetaOperario(values)
  }


  submitNuevaOperario (values) {
    if (this.props.data){
      this.props.actions.editarOperario(values)
    } else {
      this.props.actions.submitNuevaOperario(values)
    }
  }

  guardarYnuevoOperario(values){
    this.props.actions.guardarYnuevoOperario(values)
  }

  UNSAFE_componentWillMount () {
    this.props.actions.comboProvinciasClientes()
    this.props.actions.comboUsuariosNoAsignadosOperario()
  }

  render () {
    const {
      t, handleSubmit, showModal, list, data, duplicar,
      actions: {recuperarDatosModalOperario, closeModal},
      combos: {comboProvinciasClientes, comboUsuariosNoAsignadosOperario}
    } = this.props
    return (
      <Modal show={showModal} onHide={closeModal} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-operario">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            data ? (
              t('OPERARIO.MODAL.TITULO_EDITAR')
            ) : (
              t('OPERARIO.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {data && data.idOperario && (
            <ButtonChangeRecord list={list} idElemento="idOperario" currentId={data.idOperario} getNextRecord={recuperarDatosModalOperario} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="nombreOperario"
              name="nombreOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.NOMBRE') + '*'}
              claseActivo="nombre-operario"
              validate={required}
              maxLength={true}
              valorMaxLength={20}
            />
            <Field
              id="apellidosOperario"
              name="apellidosOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.APELLIDOS')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="dniOperario"
              name="dniOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.DNI')}
              claseActivo="dni-operario"
              maxLength={true}
              valorMaxLength={20}
            />
            <Field
              id="emailOperario"
              name="emailOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.EMAIL')}
              claseActivo="email-operario"
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="telefonoOperario"
              name="telefonoOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.TELEFONO')}
              claseActivo="telefono-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="movilOperario"
              name="movilOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.MOVIL')}
              claseActivo="movil-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="direccionOperario"
              name="direccionOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.DIRECCION')}
              claseActivo="direccion-operario"
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="cpOperario"
              name="cpOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.CP')}
              claseActivo="cp-operario"
              maxLength={true}
              valorMaxLength={10}
            />
            <Field
              id="localidadOperario"
              name="localidadOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.LOCALIDAD')}
              claseActivo="localidad-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="provinciaOperario"
              name="provincia"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.PROVINCIA')}
              claseActivo="provincia-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="paisOperario"
              name="paisOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.PAIS')}
              claseActivo="pais-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              colSm={4}
              id="usuario"
              name="usuario"
              controlLabel={t('OPERARIO.MODAL.USUARIO')}
              component={InputSelect}
              options={comboUsuariosNoAsignadosOperario}
              valueKey="value"
              labelKey="label"
              customClass='nombre-operario'
            />
            <Field
              id="rfid"
              name="rfid"
              colSm={4}
              component={InputText}
              controlLabel="RFID"
              claseActivo='rfid-operario'
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="activo"
              name="activo"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('OPERARIO.MODAL.ACTIVO')}
            />
            <Field
              id="opeProcesado"
              name="opeProcesado"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('OPERARIO.MODAL.OPERARIO_DE_PROCESADO')}
            />
            {/*this.props.auth.modulos.includes(modulosConstant.PEDIDOS_CLIENTES) && (
              <Field
                id="opeAlbaran"
                name="opeAlbaran"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('OPERARIO.MODAL.OPERARIO_DE_ALBARAN')}
              />
            )*/}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          { this.props.data && (this.props.auth.version >= 2 || this.props.auth.customizations && this.props.auth.customizations.find((customization) => {return customization=='USA'})) &&
              <Button
              type="button"
              className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.OPERARIOS] < 2) ? ' disabled' : '')}
              onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1) ? handleSubmit(this.handleImprimirEtiquetaOperario.bind(this)) : undefined}
              >{t('ANALISIS_EXTRACCIONES.FORM.BUTTONS.IMPRIMIR_ETIQUETA')}</Button>
          }
          {
            this.props.data && (
              <Button
                type="button"
                className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.OPERARIOS] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1) ? handleSubmit(duplicar) : undefined}
              >{t('OPERARIO.MODAL.DUPLICAR')}</Button>
            )
          }
          {
            !this.props.data && (
              <Button
                type="button"
                className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.OPERARIOS] < 2) ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1) ? this.props.submitSucceeded === false ? handleSubmit(this.guardarYnuevoOperario.bind(this)) : null : undefined}
              >{t('OPERARIO.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )
          }
          <Button
            type="button"
            onClick={this.props.submitSucceeded === false ? handleSubmit(this.submitNuevaOperario.bind(this)) : null}
            className={"btn btn-primary" + (this.props.submitSucceeded === true || (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.OPERARIOS] < 2) ? ' disabled' : '')}
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.OPERARIOS] < 2)}
          >{t('OPERARIO.MODAL.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModal}>{t('OPERARIO.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalOperario',
})(ModalOperario))