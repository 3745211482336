import {select, put, call, all, takeLatest} from 'redux-saga/effects'
import { initialize, change, getFormValues } from 'redux-form';
import i18n from '../../../i18n';
import {downloadDocument} from '../../../util/util'

// Constants
import actionTypes from '../../../constants/actions/planSanitario/controlSangre/controlSangre'

// Actions
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../../actions/common'
import {
  fetchControlSangreSuccess, openModalListadoControlSangreSuccess, closeModalListadoControlSangreSuccess, abrirModalControlSangre, closeModalControlSangre,
  deSeleccionarTodosModalControlSangre, fetchFiltrarModalInicioSuccess, closeModalInicio, recuperarDatosModalControlSangreSuccess
} from '../../../actions/planSanitario/controlSangre/controlSangre'

// Services
import getFiltrarControlSangreListService from '../../../services/planSanitario/controlSangre/getFiltrarControlSangreList'
import getFiltrarModalInicio from '../../../services/planSanitario/controlSangre/getFiltrarModalInicio'
import deleteControlSangreService from '../../../services/planSanitario/controlSangre/deleteControlSangre'
import deleteSeleccionControlSangreService from '../../../services/planSanitario/controlSangre/deleteSeleccionControlSangre'
import getCrearCsvControlSangreService from '../../../services/planSanitario/controlSangre/getCrearCsvControlSangre'
import getImprimirPdfControlSangreService from '../../../services/planSanitario/controlSangre/getImprimirPdfControlSangre'
import submitNuevoControlSangreService from '../../../services/planSanitario/controlSangre/submitNuevoControlSangre'
import getDescargarArchivoControlSangreService from '../../../services/planSanitario/controlSangre/getDescargarArchivoControlSangre'
import etiquetasControlSangre from '../../../services/planSanitario/controlSangre/etiquetasControlSangre';
import getControlSangre from '../../../services/planSanitario/controlSangre/getControlSangre';
import updateControlSangre from '../../../services/planSanitario/controlSangre/updateControlSangre';


// Sagas
import {yesNoModal as yesNoModalSaga} from '../../modal/yesNoModal'

export function * fetchControlSangre ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    let mainList = []
    mainList = yield call(getFiltrarControlSangreListService, filtros, auth.token)

    mainList.length > 0 && mainList.forEach((item) => {
      item.tipoControl = (item.tipoControl && item.tipoControl  == 1 ? i18n.t('COMUN.COMBOS.CONTROL_SEMEN.RUTINARIO') : item.tipoControl)
      item.tipoControl = (item.tipoControl && item.tipoControl  == 2 ? i18n.t('COMUN.COMBOS.CONTROL_SEMEN.DIRIGIDO') : item.tipoControl)
      item.tipoDirigido = (item.tipoDirigido && item.tipoDirigido  == 1 ? i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.PCR_SEMEN') : item.tipoDirigido)
      item.tipoDirigido = (item.tipoDirigido && item.tipoDirigido  == 2 ? i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.ENFERMEDAD') : item.tipoDirigido)
      item.tipoDirigido = (item.tipoDirigido && item.tipoDirigido  == 3 ? i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.CONTACTO') : item.tipoDirigido)
      item.tipoDirigido = (item.tipoDirigido && item.tipoDirigido  == 4 ? i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.SEROLOGIA') : item.tipoDirigido)
      item.resultado = (item.resultado && item.resultado  == 1 ? i18n.t('COMUN.COMBOS.RESULTADO.POSITIVO') : item.resultado)
      item.resultado = (item.resultado && item.resultado  == 2 ? i18n.t('COMUN.COMBOS.RESULTADO.NEGATIVO') : item.resultado)
      item.resultado = (item.resultado && item.resultado  == 3 ? i18n.t('COMUN.COMBOS.RESULTADO.DUDOSO') : item.resultado)
      return item 
    })

    yield put(fetchControlSangreSuccess(mainList, filtros))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchControlSangre () {
  yield takeLatest(actionTypes.FETCH_CONTROL_SANGRE, fetchControlSangre)
}

export function * closeModalListadoControlMorfologico () {
  try {
    let state = yield select(state => state)

    // Si se cierra el modal-listado desde el apartado principal de seguimientos, se recargara el listado con los filtros almacenados,
    // Si se cierra el modal-listado desde el modal de edición de analisis/pool, se actualizara el checkbox de seguimiento.
    if (state.seguimiento.esApartadoPrincipal) {
      yield call(fetchControlSangre, {filtros: state.seguimiento.filtros})
    } else {
      let newSeguimiento = state.seguimiento.list.length > 0
      let oldSeguimiento
      let formName = ''
      if (state.seguimiento.idAnalisis) {
        formName = 'formAnalisisExtraccion'
        oldSeguimiento = yield getFormValues(formName)(state).seguimiento
      } else if (state.seguimiento.idPool) {
        formName = 'formPoolExtraccion'
        oldSeguimiento = yield getFormValues(formName)(state).seguimiento
      }

      if (newSeguimiento !== oldSeguimiento) {
        yield put(change(formName, 'seguimiento', newSeguimiento))
      }
    }
    yield put(closeModalListadoControlSangreSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCloseModalListadoControlMorfologico () {
  yield takeLatest(actionTypes.CLOSE_MODAL_LISTADO_CONTROL_SANGRE, closeModalListadoControlMorfologico)
}

export function * deleteControlSangre ({row}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      const {eliminado} = yield call(deleteControlSangreService, row.idControlSangre, null)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }


      let state = yield select(state => state)
      yield call(fetchControlSangre, {filtros: state.controlSangre.filtros})
 
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteControlSangre () {
  yield takeLatest(actionTypes.DELETE_CONTROL_SANGRE, deleteControlSangre)
}

export function * deleteSeleccionControlSangre ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const {eliminado} = yield call(deleteSeleccionControlSangreService, list, auth.token)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosModalControlSangre())
      yield put(initialize('listadoControlSangreTable', {}))
      yield put(initialize('ControlSangreTable', {}))

      
      let state = yield select(state => state)
      yield call(fetchControlSangre, {filtros: state.controlSangre.filtros})
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteSeleccionControlSangre () {
  yield takeLatest(actionTypes.DELETE_SELECCION_CONTROL_SANGRE, deleteSeleccionControlSangre)
}

export function * crearCsvControlSangre ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelControlSangre = yield call(getCrearCsvControlSangreService, list, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(datosExcelControlSangre.excel)
    let name = i18n.t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.CONTROL_SANGRE') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCrearCsvControlSangre () {
  yield takeLatest(actionTypes.CREATE_CSV_CONTROL_SANGRE, crearCsvControlSangre)
}

export function * imprimirPdfControlSangre ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfControl= yield call(getImprimirPdfControlSangreService, list, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfControl.pdf)
    let name = i18n.t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.CONTROL_SANGRE') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchImprimirPdfControlSangre () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_CONTROL_SANGRE, imprimirPdfControlSangre)
}


export function * descargarArchivoControlSangre ({controlSangre}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfControl= yield call(getDescargarArchivoControlSangreService, controlSangre, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfControl.archivo)
    let name = datosPdfControl.nombreArchivo
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDescargarArchivoControlSangre () {
  yield takeLatest(actionTypes.DESCARGAR_ARCHIVO_CONTROL_SANGRE, descargarArchivoControlSangre)
}


export function * fetchFiltrarModalInicio ({values}) {
  try {    
    const auth = yield select(state => state.auth)
    const list = yield call(getFiltrarModalInicio, values, auth.token)
    yield put(fetchFiltrarModalInicioSuccess(list,values))
    // if (Object.keys(list).length > 0) {
    //   yield put(abrirModalInicio())
    // }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchFiltrarModalInicioControlSangre () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_MODAL_INICIO_CONTROL_SANGRE, fetchFiltrarModalInicio)
}

export function * anadirVerracosModalInicio ({listVerracosAsignados, modalValues, archivos}) {
  try {
    //console.log('sagas-fetchFiltrarModalInicio',listVerracosAsignados)
    const auth = yield select(state => state.auth)
    const filtros = yield select(state => state.form.FiltrosControlSangre.values)
    let body = {
      listVerracosAsignados: listVerracosAsignados,
      modalValues: modalValues,
      archivos: archivos
    }
    const status = yield call(submitNuevoControlSangreService, body, auth.token)
    
    yield call(fetchControlSangre, {filtros})
    yield put(closeModalInicio())

    if (status.insertado === true) {
      yield put(openMensajeAlerta('insercion-planing'))
    } else {
      yield put(openMensajeAlerta('no-insercion-planing'))
    }

    // const list = yield call(getFiltrarModalInicio,  {values}, auth.token)
    // yield put(fetchFiltrarModalInicioSuccess(listVerracosAsignados))
    // if (Object.keys(list).length > 0) {
    //   yield put(abrirModalInicio())
    // }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchAnadirVerracosModalInicioControlSangre () {
  yield takeLatest(actionTypes.ANADIR_VERRACOS_INICIO_CONTROL_SANGRE, anadirVerracosModalInicio)
}

export function * imprimirEtiquetaControlSangre ({filtros}) {   
   try {
    const auth = yield select(state => state.auth)
    const etiquetaPdf = yield call(etiquetasControlSangre, filtros, auth.token)
           
    let selected_device;
    let devices = [];
    window.BrowserPrint.getDefaultDevice("printer", function (device) {
      selected_device = device;
      devices.push(device);
      window.BrowserPrint.getLocalDevices(function (device_list) {
        for (let i = 0; i < device_list.length; i++) {
          let device = device_list[i];
          if (!selected_device || device.uid != selected_device.uid) {
            devices.push(device);
          }
        }
        selected_device.send(etiquetaPdf.zebra, undefined, undefined)
      },
        function () { console.log("Error getting local devices") }, "printer");
    }, function (error) {
      console.log(error);
    })
  
  } catch (error) {
    console.log(error)
  }  
}

export function * watchimprimirEtiquetaControlSangre () {
  yield takeLatest(actionTypes.IMPRIMIR_ETIQ_CONTROL_SANGRE, imprimirEtiquetaControlSangre)
}

export function * recuperarDatosModalControlSangre ({idControlSangre}) {
  try {
    let registroModificar = yield call(getControlSangre, idControlSangre, null)
    if(registroModificar!=null)
    {
      if (registroModificar.tipoControl && registroModificar.tipoControl  == 1) registroModificar.tipoControl = i18n.t('COMUN.COMBOS.CONTROL_SEMEN.RUTINARIO')
      if (registroModificar.tipoControl && registroModificar.tipoControl  == 2) registroModificar.tipoControl = i18n.t('COMUN.COMBOS.CONTROL_SEMEN.DIRIGIDO')

      if (registroModificar.tipoDirigido == 1) registroModificar.tipoDirigido = i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.PCR_SEMEN')
      if (registroModificar.tipoDirigido == 2) registroModificar.tipoDirigido = i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.ENFERMEDAD')
      if (registroModificar.tipoDirigido == 3) registroModificar.tipoDirigido = i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.CONTACTO')
      if (registroModificar.tipoDirigido == 4) registroModificar.tipoDirigido = i18n.t('COMUN.COMBOS.TIPO_DIRIGIDO.SEROLOGIA')
    }
     yield put(recuperarDatosModalControlSangreSuccess(registroModificar))    

     yield put(abrirModalControlSangre())
     
    if (registroModificar.resultado === 1){
      registroModificar.resultado = {value: 1, label: i18n.t('COMUN.COMBOS.RESULTADO.POSITIVO')}
    } 
    if (registroModificar.resultado === 2){
      registroModificar.resultado = {value: 2, label: i18n.t('COMUN.COMBOS.RESULTADO.NEGATIVO')}
    }
    if (registroModificar.resultado === 3){
      registroModificar.resultado = {value: 3, label: i18n.t('COMUN.COMBOS.RESULTADO.DUDOSO')}
    }

    yield put(initialize('ModalControlSangre', registroModificar)) 
  } catch (error) {

  } 
}

export function * watchRecuperarDatosModalControlSangre () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_CONTROL_SANGRE, recuperarDatosModalControlSangre)
}


export function * guardarDetalleControlSangre ({data}) {
  try {
    const auth = yield select(state => state.auth)
    let $resultado = yield call(updateControlSangre, data, auth.token)

    if ($resultado.actualizado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }

    yield put(closeModalControlSangre())

    if ($resultado.actualizado === true) { 
      const filtros = yield select(state => state.form.FiltrosControlSangre.values)
      yield call(fetchControlSangre, {filtros})  
    }

  } catch (error) {
      console.log("error en saga DEVOLVER" ,error)
  } finally { } 
  
}
export function * watchguardarDetalleControlSangre () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_CONTROL_SANGRE, guardarDetalleControlSangre)
}