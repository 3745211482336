
export const analisisExtraccion = {
  TODOS: 1,
  PREVISTO: 2
}

export const translateAnalisisExtraccion = {
  1: 'ANALISIS_EXTRACCION.TODOS',
  2: 'ANALISIS_EXTRACCION.PREVISTO'
}

export const tipoAnalisisExtraccion = {
  COLORIMETRO: 1,
  CAMARA_BURKER: 2,
  MAGAVISION_I: 3,
  MAGAVISION_M: 4,
  NUEVO_MAGAVISION: 5,
  MAGAVISION_ML: 6,
  CAMARA_NEUBAUER: 7,
}

export const translateTipoAnalisisExtraccion = {
  1: 'TIPO_ANALISIS_EXTRACCION.COLORIMETRO',
  2: 'TIPO_ANALISIS_EXTRACCION.CAMARA_BURKER',
  3: 'TIPO_ANALISIS_EXTRACCION.MAGAVISION_I',
  4: 'TIPO_ANALISIS_EXTRACCION.MAGAVISION_M',
  5: 'TIPO_ANALISIS_EXTRACCION.NUEVO_MAGAVISION',
  6: 'TIPO_ANALISIS_EXTRACCION.MAGAVISION_ML',
  7: 'TIPO_ANALISIS_EXTRACCION.CAMARA_NEUBAUER'
}

export const observacionesEyaculadoEliminado = {
  PROBLEMAS_ENTRENAMIENTO: 1,
  AGOTAMIENTO: 2,
  EXCEDENTE_MACHO_ERRONEO: 3,
  BAJA_MOTILIDAD: 4,
  BAJA_CONCENTRACION: 5,
  ALTA_MORFOLOGIA: 6,
  ALTA_AGLUTINACION: 7,
  ORINA: 8,
  SANGRE: 9,
  PUS: 10,
  OTROS: 11,
  CONTAMINACION: 12,
  SEMEN_MUERTO: 13
}

export const translateObservacionesEyaculadoEliminado = {
  1: 'OBSERVACIONES_EYACULADO_ELIMINADO.PROBLEMAS_ENTRENAMIENTO',
  2: 'OBSERVACIONES_EYACULADO_ELIMINADO.AGOTAMIENTO',
  3: 'OBSERVACIONES_EYACULADO_ELIMINADO.EXCEDENTE_MACHO_ERRONEO',
  4: 'OBSERVACIONES_EYACULADO_ELIMINADO.BAJA_MOTILIDAD',
  5: 'OBSERVACIONES_EYACULADO_ELIMINADO.BAJA_CONCENTRACION',
  6: 'OBSERVACIONES_EYACULADO_ELIMINADO.ALTA_MORFOLOGIA',
  7: 'OBSERVACIONES_EYACULADO_ELIMINADO.ALTA_AGLUTINACION',
  8: 'OBSERVACIONES_EYACULADO_ELIMINADO.ORINA',
  9: 'OBSERVACIONES_EYACULADO_ELIMINADO.SANGRE',
  10: 'OBSERVACIONES_EYACULADO_ELIMINADO.PUS',
  11: 'OBSERVACIONES_EYACULADO_ELIMINADO.OTROS',
  12: 'OBSERVACIONES_EYACULADO_ELIMINADO.CONTAMINACION',
  13: 'OBSERVACIONES_EYACULADO_ELIMINADO.SEMEN_MUERTO'
}

export const concentracionDisponible = {
  TOTAL: 1, // También llamada 'concentración aplicada'.
  UTIL: 2,
}