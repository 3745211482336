import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalOperarioPage from '../../containers/operario/ModalOperarioPage'
import FiltrosOperarioPage from '../../containers/operario/FiltrosOperarioPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

class Operario extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.MANTENIMIENTO_DATOS.OPERARIOS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarOperario({activo:{value: true, label: this.props.t('ESTADO.ACTIVO')}})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      operario: { list, data, dataDuplicar, dataEstado, checkedFiltrosOperario, dataDelete},
      actions: {recuperarDatosModalOperario, duplicarDatosModalOperario, cambiarEstadoOperario, onDeleteOperario, cambiarOperarioProcesado, cambiarOperarioAlbaran }
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idOperario
    )

    const table = {
      id: 'operarioTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1),
      hasOptions: true,
      columns: [
        {id: 'nombreOperario', name: t('OPERARIO.TABLA.NOMBRE')},
        {id: 'apellidosOperario', name: t('OPERARIO.TABLA.APELLIDOS')},
        {id: 'telefonoOperario', name: t('OPERARIO.TABLA.TELEFONO')},
        {id: 'movilOperario', name: t('OPERARIO.TABLA.MOVIL')},
        {id: 'emailOperario', name: t('OPERARIO.TABLA.EMAIL')},
        {id: 'localidadOperario', name: t('OPERARIO.TABLA.LOCALIDAD')},
        {id: 'opeProcesado', name: t('OPERARIO.TABLA.PROCESADO')},
        /*(this.props.auth.modulos.includes(modulosConstant.PEDIDOS_CLIENTES) && {id: 'opeAlbaran', name: t('OPERARIO.TABLA.ALBARAN')}),*/
        {id: 'nombreUsuario', name: t('OPERARIO.TABLA.USUARIO')},
        {id: 'rfid', name: "RFID"}
      ],
      rows: list,
      filtros: <FiltrosOperarioPage />,
      onEdit: (row) => recuperarDatosModalOperario(row.idOperario),
      data: data,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1,
      duplicar: (row) =>duplicarDatosModalOperario(row.idOperario),
      dataDuplicar: dataDuplicar,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1,
      cambiarEstado: (row) => cambiarEstadoOperario(row.idOperario),
      dataEstado: dataEstado,
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1,
      onDelete: (row) => onDeleteOperario(row.idOperario, row.nombreOperario, row.apellidosOperario, row.localidadOperario),
      dataDelete: dataDelete,
      operarioProcesado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1,
      cambiarOperarioProcesado: (row) => cambiarOperarioProcesado(row.idOperario),
      // operarioAlbaran: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1,
      // cambiarOperarioAlbaran: (row) => cambiarOperarioAlbaran(row.idOperario),
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosOperario
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalOperarioPage
          duplicar={(row) =>duplicarDatosModalOperario(row.idOperario)}
        />
        <ListadoPage t_key="OPERARIO." table={table}>
        </ListadoPage>
      </div>
    )
  }
}

export default Operario